import React from 'react';
import appLogo from '../assets/images/applogo.png';
import {EmailOutline} from '@styled-icons/evaicons-outline/EmailOutline'
const Footer = () => (
  <footer className="bg-gradient-to-r from-green-900 to-teal-800 text-white pt-10 pb-10">
    <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
      <div className="flex justify-center md:justify-start">
        <div className="flex items-center space-x-4">
            <img src={appLogo} className="h-10" alt="logo" />
            <div className="text-2xl font-bold">SebuPay</div>
        </div>
      </div>
      <div className="flex justify-center md:justify-end">
          <div>
            {/* <div className="mb-4 inline-block">{<EmailOutline size={24} color='#D0E201'/>}</div> */}
            <a href="mailto:hello@sebupay.com" className="hover:cursor-pointer"><div className="text-lg font-medium text-white">hello@sebupay.com</div></a>
            <p className='text-gray-400 font-medium'>Copyright: © 2024 SebuPay. All Rights Reserved</p>
          </div>
      </div>
    </div>
  </footer>
);

export default Footer;
