import {MoneyHand} from '@styled-icons/fluentui-system-filled/MoneyHand'
import {Money} from '@styled-icons/material-sharp/Money'
import {Group} from '@styled-icons/remix-line/Group'
import {Funds} from '@styled-icons/remix-line/Funds'

const OverviewItem = ({ title, description, icon }) => (
    <div className="text-center p-4">
        <div className="mb-4 inline-block p-4 rounded-full bg-gray-100">{icon}</div>
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className='font-medium text-gray-500'>{description}</p>
    </div>
);

const OverviewSection = () => (
    <section className="container mx-auto px-6 py-12">
        <div className="text-center">
        <h2 className="text-4xl font-bold mb-4">The only fintech app you'll ever need</h2>
        <p className="mb-12">Centred on creating a sustainable financial ecosystem</p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <OverviewItem
            icon={<Money size={44} color='#13524d'/>}
            title="Low transaction fees"
            description="Enjoy as low as 0.5% per transaction."
        />
        <OverviewItem
            icon={<Group size={44} color='#13524d'/>}
            title="Peer-to-peer lending"
            description="Direct loans to individuals and businesses."
        />
        <OverviewItem
            icon={<Funds size={44} color='#13524d'/>}
            title="Low interest loans"
            description="We provide loans that are as low as 5% per annum."
        />
        <OverviewItem
            icon={<MoneyHand size={44} color='#13524d'/>}
            title="Flexible repayments"
            description="Repay your loan with zero pressure"
        />
        </div>
    </section>
);

export default OverviewSection;