import React, { useState } from 'react';
import sebudash from '../../assets/images/sebudash.png';
import sebufunds from '../../assets/images/sebufunds.png';
import sebuloanAppl from '../../assets/images/sebuloanAppl.png';

// You might want to replace these with actual content and images
const content = [
  {
    id: 'transactions',
    title: 'Transactions at a Glance',
    description: 'The intuitive interface allows for quick deposits, receipts, and loan oversight, ensuring you are always on top of your finances.',
    imageUrl: sebudash
  },
  {
    id: 'finOverview',
    title: 'Comprehensive Financial Overview',
    description: 'Keep track of your overall balance, investment portfolio, and unpaid loans in one place. Analyze your financial health.',
    imageUrl: sebufunds
  },
  {
    id: 'easyLoan',
    title: 'Easy Loan Application',
    description: 'Access financial support when you need it. Input your desired loan amount, explain your needs, and repayment plan',
    imageUrl: sebuloanAppl
  }
];

const FeaturesTab = ({ content, active, setActive }) => (
  <div
    className={`p-4 cursor-pointer`}
    style={active ? { backgroundColor: '#F0FFD3' } : { backgroundColor: '#fcfcfc' }}
    onClick={() => setActive(content.id)}
  >
    <h3 className="text-lg font-bold">{content.title}</h3>
    <p className='font-medium text-gray-500'>{content.description}</p>
  </div>
);

const FeatureContent = ({ activeContent }) => (
  <div className="flex justify-center items-center p-4">
    <img 
      src={activeContent.imageUrl} 
      alt={activeContent.title} 
      className="w-3/5 md:w-1/2 lg:w-2/5" // Adjust the width on different screen sizes
    />
  </div>
);

const InteractiveFeatureSection = () => {
  const [activeTab, setActiveTab] = useState(content[0].id);
  const activeContent = content.find(item => item.id === activeTab);

  return (
    <section className="container mx-auto my-12 p-4 rounded-md shadow-lg bg-white">
      <div className='flex flex-col md:flex-row items-center md:items-start'>
        <div className="flex flex-col w-full md:w-1/3">
          <h2 className="text-3xl font-bold mb-4">Interactive Features</h2>
          {content.map((item) => (
            <FeaturesTab
              key={item.id}
              content={item}
              active={activeTab === item.id}
              setActive={setActiveTab}
            />
          ))}
        </div>
        <div className="w-full md:flex-1">
          <FeatureContent activeContent={activeContent} />
        </div>
      </div>
    </section>
  );
};

export default InteractiveFeatureSection;
