import appLogo from '../assets/images/applogo.png';
const TopBar = () => {
  return (
    <header className="w-full p-4 fixed text-white top-0 z-10 bg-gradient-to-r from-[#487862] to-gray-800">
      <div className="container mx-auto flex justify-between items-center">
      <div className="flex items-center space-x-4">
        <img src={appLogo} className="h-10" alt="logo" />
        <div className="text-2xl font-bold">SebuPay</div>
      </div>
        <nav>
          <ul className="flex space-x-4 font-medium">
            {/* <li className="hover:cursor-pointer">Overview</li>
            <li className="hover:cursor-pointer">Features</li> */}
          </ul>
        </nav>
        {/* <button className="bg-white text-green-600 py-2 px-4 rounded-md font-bold">Get Started</button> */}
      </div>
    </header>
  );
};

export default TopBar;
