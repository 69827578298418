import TopBar from '../components/TopBar';
import Footer from '../components/Footer';
import TopSection from '../components/sections/TopSection';
import OverviewSection from '../components/sections/OverviewSection';
import InteractiveFeatureSection from '../components/sections/InteractiveFeatureSection';
import DirectLoanSection from '../components/sections/DirectLoanSection';
import CreditScoreSection from '../components/sections/CreditScoreSection';
import VideoSection from '../components/sections/VideoSection';
const HomePage = () => {
    return (
    <div>
        <TopBar />
        <main className="pt-1">
            <TopSection />
            <OverviewSection />
            <VideoSection />
            <InteractiveFeatureSection />
            <DirectLoanSection />
            <CreditScoreSection />
            <Footer />
        </main>
    </div>
    )
}

export default HomePage;