import React from 'react';
import sebuloancreate from '../../assets/images/sebuloancreate.png';

const DirectLoanSection = () => (
    <section className="container mx-auto my-12 p-4 grid grid-cols-1 lg:grid-cols-2 gap-8">
    {/* Image placeholder for transactions */}
    <div className="bg-gray-100 rounded-lg overflow-hidden">
      <div className="flex justify-center items-center">
        {/* Replace src with your transactions image path */}
        <img src={sebuloancreate} alt="Transactions" className="w-full" />
      </div>
    </div>

    {/* Text content */}
    <div className="flex flex-col justify-center">
      <h2 className="text-3xl font-bold mb-4">Be in complete control over your money</h2>
      <p className="text-gray-600 font-medium">
      Empower your financial transactions with our Direct Loan feature. 
      This function allows you to lend money to individuals while setting clear terms for the loan, 
      including the interest rate and loan duration. Transparently calculate the expected returns, 
      accounting for transaction fees, and send funds with confidence. Add a personal touch by 
      including a description for the transaction, and keep your finances organized with 
      instant updates to your balance. Whether for personal or business purposes, our app makes peer-to-peer lending 
      straightforward and secure.
      </p>
    </div>
  </section>
);

export default DirectLoanSection;
