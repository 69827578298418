import React from 'react';
import YouTube from 'react-youtube';

class VideoSection extends React.Component {
  render() {
    const options = {
      playerVars: {
        autoplay: 1,
        controls: 1,
      },
    };

    return (
      <section className="container mx-auto my-12 p-4 rounded-md shadow-lg bg-black">
        <iframe width="100%" height="500" src="https://www.youtube.com/embed/VFTan8eYRqI?si=WBqsV6a3qGL_DW1D" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </section>
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
  }
}

export default VideoSection;

