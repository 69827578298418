import React from 'react';
import creditscore from '../../assets/images/creditscore.png';

const CreditScoreSection = () => (
    <section className="container mx-auto my-12 p-4 grid grid-cols-1 lg:grid-cols-2 gap-8">
    <div className="flex flex-col justify-center">
      <h2 className="text-3xl font-bold mb-4">Credit Score Insights</h2>
      <p className="text-gray-600 font-medium">
      Stay informed and empowered with our Credit Score Monitoring feature. 
      This tool provides a clear, visual representation of your credit score, 
      helping you understand your creditworthiness at a glance. The higher your score, 
      the better your chances of being approved for loans and credit lines. 
      </p>
    </div>
    <div className="bg-gray-100 rounded-xl overflow-hidden">
      <div className="flex justify-center items-center">
        <img src={creditscore} alt="Transactions" className="w-full" />
      </div>
    </div>
  </section>
);

export default CreditScoreSection;
