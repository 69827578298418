
import React, { useState } from 'react';
import section1Img from '../../assets/images/sec1_image.png';
import axios from 'axios';

const TopSection = () => {
    const [formData, setFormData] = useState({
      email: ''
    });
    const [showModal, setShowModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleEmailChange = (event) => {
        setFormData({ ...formData, email: event.target.value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post('https://sebupay.net/waitlist/join', { email: formData.email },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );
            setIsLoading(false);
            if (response.data.status === 'success') {
                setFormData({
                    email: '',
                });
                setShowModal(true);
                setModalMessage(response.data.message);
            } else {
                setShowModal(true);
                setModalMessage('Email already Exists.');
            }
        } catch (error) {
            console.error('Error adding email to waitlist', error);
            setIsLoading(false);
        }
    };
    const openWaitingListLink = (url) => {
      window.open(url, "_blank", "noreferrer");
    };

    return (
        <section className="bg-gradient-to-r from-[#487862] to-gray-800 text-white text-center">
            <h1 className="text-6xl font-bold mb-4 pt-40 text-width-70">Transforming<br></br> Financial Inclusion</h1>
            <p className="mb-4 text-gray-200 font-medium">Secure and transparent peer-to-peer lending tailored to your needs, 
            whether you're an <br></br>individual chasing dreams or a small business owner building 
            the next big thing.</p>
            {/* <form className="flex justify-center gap-2 pb-10" onSubmit={handleSubmit}>
                <input 
                type="email" 
                placeholder="Enter your email" 
                className="p-2 rounded-md text-green-800" 
                value={formData.email} onChange={handleEmailChange} required/>
                <button type="submit" className="bg-yellow-200 text-green-600 py-2 px-6 rounded-md font-bold">{isLoading ? 'Sending...' : 'Join Waitlist'}</button>
            </form> */}
            <div className='py-4'>
                <button
                    role="link"
                    onClick={() => openWaitingListLink("https://forms.visme.co/formsPlayer/w46grjvr-sebupay-waiting-list")}
                    className="bg-yellow-200 text-green-600 py-2 px-6 rounded-md font-bold">
                    Join Waitlist
                </button>
            </div>
            {showModal && (
                <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-teal-800 p-5 rounded-lg">
                        <p>{modalMessage}</p>
                        <button onClick={() => setShowModal(false)}>Close</button>
                    </div>
                </div>
            )}
            <div className="flex justify-center">
                <img src={section1Img}  alt="Descriptive text for image" className="max-w-xs md:max-w-lg"/>
            </div>
        </section>
    );
  };
  
  export default TopSection;